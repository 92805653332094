import React from 'react';
import Slider from 'react-slick';
import backgroundImage from 'assets/images/widgets-background-dark.jpg';
import backgroundImageWebp from 'assets/images/widgets-background-dark.jpg?as=webp';
import { checkWebPSupport } from 'supports-webp-sync';

import { useProgressiveImage } from '../../hooks';
import { generateKey } from '../../../shared/utils';

/**
 * @param widgetsDataStatic
* @param themeType
 * @returns {JSX.Element}
 * @constructor
 */
const AppWidgets = ({ widgetsDataStatic, themeType }) => {
  const isWebPSupport = checkWebPSupport();
  const placeholder = isWebPSupport ? backgroundImageWebp : backgroundImage;
  const loaded = useProgressiveImage(isWebPSupport ? backgroundImageWebp : backgroundImage);
  const { heading, widgets } = widgetsDataStatic;
  const Settings = {
    slideToScroll: 1,
    slidesToShow: 3,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 961,
        settings: {
          slideToScroll: 1,
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div
      className={`app-widgets ${themeType === 'dark' ? 'app-widgets--dark' : 'app-widgets--light'}`}
      style={{ backgroundImage: `url(${loaded || placeholder})` }}
    >
      <div className='container'>
        {heading
          && <h2 className='app-widgets__heading'>{heading}</h2>}
        {widgets
          && (
            <Slider
              {...Settings}
              className='app-widgets__grid'
              key={generateKey('app-widgets__grid')}
            >
              {widgets.map(({ icon, title, description }) => (
                <div className='app-widgets__item' key={generateKey('app-widgets__item')}>
                  <div className='widgets-card'>
                    <div className='widgets-card__icon'>
                      {icon}
                    </div>
                    <h3 className='widgets-card__title'>
                      {title}
                    </h3>
                    <div className='widgets-card__description'>
                      {description}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
      </div>
    </div>

  );
};

export default AppWidgets;
